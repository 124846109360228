const messages = {
  sk: {
    orders: {
      actions: {
        addNewOrder: 'Nová objednávka',
        all: 'Všetky objednávky',
      },
      listPage: {
        title: 'Objednávky',
        table: {
          filter: {
            number: 'Číslo',
          },
          columns: {
            contact: 'Kontakt',
            dateOfOrder: 'Dátum objednávky',
            number: 'Číslo',
            offer: 'Ponuka',
            opportunity: 'Príležitosť',
            owner: 'Vlastník',
            state: 'Krajina',
            total: 'Celkom',
            totalUserCurrency: 'Celkom v {currency}',
          },
        },
      },
      order: {
        newEntity: 'Nová objednávka',
        contact: {
          label: 'Kontakt',
          helperText: '',
          helperTextPreview: '',
        },
        commissionTotal: {
          label: 'Celková provízia',
          helperText: '',
          helperTextPreview: '',
        },
        currency: {
          label: 'Mena',
          helperText: '',
          helperTextPreview: '',
        },
        dateOfOrder: {
          label: 'Dátum objednávky',
          helperText: '',
          helperTextPreview: '',
        },
        number: {
          label: 'Číslo',
          helperText: 'Pre automatické doplnenie čísla ponechajte prázdne.',
          helperTextPreview: '',
        },
        offer: {
          label: 'Ponuka',
          helperText: '',
          helperTextPreview: '',
        },
        opportunity: {
          label: 'Príležitosť',
          helperText: '',
          helperTextPreview: '',
        },
        owner: {
          label: 'Vlastník',
          helperText: '',
          helperTextPreview: '',
        },
        salesMargin: {
          label: 'Marža',
          helperText: '',
          helperTextPreview: '',
        },
        subjects: {
          title: 'Názov',
          discount: {
            label: 'Zľava',
            helperText: '',
            helperTextPreview: '',
          },
          commission: {
            label: 'Provízia',
            helperText: '',
            helperTextPreview: '',
          },
          list: {
            package: 'Balenie',
          },
          listPrice: {
            label: 'Cenníková cena',
            helperText: '',
            helperTextPreview: '',
          },
          price: {
            label: 'Cena',
            helperText: '',
            helperTextPreview: '',
          },
          product: {
            label: 'Produkt',
            helperText: '',
            helperTextPreview: '',
          },
          quantity: {
            label: 'Množstvo',
            helperText: '',
            helperTextPreview: '',
          },
          salesMargin: {
            label: 'Marža',
            helperText: '',
            helperTextPreview: '',
          },
          subjectTotal: {
            label: 'Celkom',
            helperText: '',
            helperTextPreview: '',
          },
          unit: {
            label: 'Ks',
            helperText: '',
            helperTextPreview: '',
          },
        },
        total: {
          label: 'Celkom',
          helperText: '',
          helperTextPreview: '',
        },
        numberOfSubjects:
          '{number, plural, =0 {žiadne produkty} one {1 produkt} few {{number} produktov} other {{number} produkty}}',
      },
      myOrdersScoringBlog: {
        title: 'Moje objednávky',
      },
      ordersList: 'Objednávky',
    },
  },
};
export default messages;

const messages = {
  sk: {
    com: {
      components: {
        headerCartButton: {
          cartButton: 'Košík',
        },
      },
      productDetail: {
        parameters: {
          title: 'Parametre',
        },
      },
      productAvailability: {
        format: {
          available: 'Skladom',
          moreThanX: 'Viac ako {min} kusov skladom',
          exactCount: '{value} kusov skladom',
          notAvailable: 'Nie je k dispozícii',
        },
      },
      imageGallery: {
        thumbnailsMore: '+ ďalších ({number}).',
      },
      relatedProducts: {
        title: 'Súvisiace produkty',
      },
      productAccessories: {
        title: 'Príslušenstvo',
      },
      productDetailImageGallery: {
        noImage: 'Žiadne obrázky',
      },
      productAvailabilityFieldPreviewWithPopup: {
        button: 'Kedy obdržím tovar?',
      },
    },
  },
};
export default messages;

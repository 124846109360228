const messages = {
  sk: {
    parameters: {
      yes: 'A',
    },
    com: {
      phase: {
        label: 'Label',
      },
      product: {
        ean: {
          label: 'EAN',
          helperText: '',
          helperTextPreview: '',
        },
        sku: {
          label: 'Kód produktu',
          helperText: '',
          helperTextPreview: '',
        },
        manufacturer: {
          label: 'Výrobca',
          helperText: '',
          helperTextPreview: '',
        },
        pn: {
          label: 'Produktové číslo',
          helperText: '',
          helperTextPreview: '',
        },
        warranty: {
          label: 'Záruka',
          months: 'mesiacov',
        },
      },
      totalAmount: {
        label: 'Celkom',
        helperText: '',
      },
    },
    imageGallery: {
      thumbnailsMore: '+ ďalších ({number}).',
    },
    orders: {
      actions: {
        all: 'Všetky objednávky',
      },
      myOrdersScoringBlog: {
        title: 'Moje objednávky',
      },
      ordersList: 'Objednávky',
      phase: {
        1: 'Prijatá',
        '01-new': 'Prijatá',
        10: 'Otvorená',
        20: 'Čaká na platbu',
        30: 'Zaplatená',
        98: 'Dokončená',
        99: 'Zrušená',
      },
      listPage: {
        table: {
          columns: {
            packageId: 'Číslo balíka',
            phase: 'Stav',
            paid: 'Zaplatené',
            total: 'Bez DPH',
            totalWithTax: 'Celkom',
          },
        },
      },
    },
    baskets: {
      cartSinglePage: {
        title: 'Košík',
        buyButton: 'Odoslať objednávku',
        emptyBasket: 'Váš nákupný košík je prázdny',
        buyThere: 'Už som tu nakupoval',
        login: 'Prihlásenie',
        notFill: 'Údaje o doručení nie sú vyplnené',
        fill: 'Vyplňte informácie',
        change: 'Zmeňte dáta',
        popupButton: 'Zobraziť na mape',
        payment: {
          title: 'SPÔSOB PLATBY',
          free: 'Zadarmo',
          changePaymentBtn: 'ZMENA PLATBY',
        },
        shipping: {
          title: 'SPÔSOB DORUČENIA',
          free: 'Zadarmo',
          changeShippingBtn: 'ZMENA DOPRAVY',
          popup: {
            title: 'Informácie',
            text: 'Doručenie na vašu adresu kuriérom {service}',
          },
        },
      },
      actions: {
        all: 'Nákupné košíky',
      },
      myBasketsScoringBlog: {
        title: 'Uložené nákupné košíky',
      },
      cartSummaryParameters: {
        caption: 'Parametre',
      },
    },
    productDetailSpagettyNavigation: {
      description: 'Popis',
      parameters: 'Parametre',
      package: 'Obsah balenia',
      fotoAndVideo: 'Obrázky a video',
      alternatives: 'Alternatívy',
      alternativesText: 'Bohužiaľ v súčasnej dobe nemáme náhradu za vami vybraný produkt.',
      questions: 'Otázka',
      productDescriptionNotAvailable: 'Popis produktu nie je k dispozícii',
    },
    newsletter: {
      phone: '+386 1 82 82 053',
      subscribe: {
        title: 'Nenechajte si ujsť špeciálne ponuky',
        inputText: 'Vaša emailová adresa',
        button: 'Odoslať',
      },
    },
    globalNavigation: {
      home: 'Úvodná stránka',
      shopping: 'Nakupovať',
      orders: 'Objednávky',
      shoppingLists: 'Nákupné zoznamy',
      articles: 'Novinky',
    },
    homePageWhy: {
      0: {
        title: 'Nakupujte bezpečne',
        content: 'Sme overeným zákazníkom portálu Heureka a vlastníme certifikát SAEC.',
      },
      1: {
        title: 'Výdajné miesta',
        content: 'Tovar si taktiež môžete vyzdvihnúť na viac ako 2000 výdajných miestach.',
      },
      2: {
        title: 'Tovar vo vlastnom sklade',
        content: '98% všetkého tovaru máme skladom a je pripravených na okamžitú expedíciu.',
      },
      3: {
        title: 'Doprava zadarmo',
        content: 'Pri nákupe vybraných digitálnych fotoaparátov a objektívov máte dopravu ZADARMO.',
      },
      title: 'PREČO U NÁS?',
    },
    startPage: {
      title: 'Úvodná strana',
      gotoShopping: 'Nakupovať',
    },
    shoppingCart: {
      actions: {
        buy: 'PRIDAŤ DO KOŠÍKA',
      },
      buyBtn: 'PRIDAŤ DO KOŠÍKA',
      alternatives: 'ALTERNATÍVA',
      shipping: {
        changePoint: 'Zmeniť',
      },
      agreements: {
        termsRequired: 'Musíte súhlasiť s obchodnými podmienkami.',
      },
      validation: {
        someProductNotAvailable: 'Niektoré produkty nie sú skladom v dostatočnom množstve.',
      },
    },
    appCartSummaryProductParameters: {
      title: 'Kód produktu:',
    },
    productListPage: {
      title: 'Katalóg',
      orders: {
        recommended: 'Odporúčame',
        name: 'Meno',
        priceAsc: 'Od najvyššej ceny',
        priceDesc: 'Od najnižšej ceny',
      },
    },
    orderStatuses: {
      storno: 'Storno',
      synchronized: 'Odoslané',
      waiting: 'Čakanie na synchronizáciu',
    },
    orderPreview: {
      items: 'Produkty',
    },
    cartRegisterForm: {
      title: 'Registračný formulár',
    },
    cartPayment: {
      title: 'SPÔSOB PLATBY',
    },
    cartPreSummaryPage: {
      title: 'Prehľad košíka',
      actions: {
        nextStep: 'Súhlasím a pokračujem',
      },
    },
    cartCompanyForm: {
      description: 'Vyplňte, ak nakupujete tovar pre firmu',
    },
    cartDeliveryForm: {
      description: 'Vyplňte, ak sa dodacia adresa líši od fakturačnej adresy',
    },
    productAvailabilityFieldPreviewWithPopup: {
      button: 'Kedy obdržím objednaný tovar?',
    },
    productDetailStockInfo: {
      available: 'Produkt máme fyzicky v našom distribučnom sklade.',
      external: 'Produkt je na ceste do nášho skladu',
      notAvailable: 'Tovar je dočasne nedostupný',
      labels: {
        free: 'DOPRAVA ZADARMO',
        showroom: 'VYSTAVENÉ V SHOWROOM',
      },
    },
    productGifts: {
      btnName: 'DARČEK',
      giftValue: 'V hodnote',
    },
    homepage: {
      productOfMonth: 'AKCIA TÝŽDŇA',
      catalogFavorite: 'OBĽÚBENÉ KATEGÓRIE',
      hotTips: 'Horúce tipy',
      recommended: 'Odporúčame',
    },
    productQuestion: {
      firstName: 'Meno',
      lastName: 'Priezvisko',
      email: 'E-mail',
      subject: 'Produkt',
      question: 'Opýtať sa',
      gdpr: 'Odoslaním dotazu súhlasím so spracovaním osobných údajov za účelom získania odpovede.',
      gdprLink: 'odkaz na GDPR',
      button: 'Odoslať',
      subjectContent: 'Otázka na produkt: {product}, kód: {sku}',
      title: 'Musíte súhlasiť so spracovaním osobných údajov.',
      success: 'Formulár bol odoslaný',
    },
    lastVisitedProducts: 'Navštívené produkty',
    productAlternativeProducts: 'Alternatívy',
    productAccessoryProducts: 'Príslušenstvo',
    productReplacementProducts: 'Alternatívy',
    productAccessories: 'Príslušenstvo',
    productReplacements: 'Náhrady',
    appProductDetailTabs: {
      description: 'Popis',
      parameters: 'Parametre',
      package: 'Obsah balenia',
      query: 'Otázka',
      media: 'Obrázky a video',
      alternatives: 'Alternatívy',
      accessories: 'Príslušenstvo',
      replacements: 'Príslušenstvo',
      form: {
        enum: '!!!! ENUM HODNOTA',
      },
    },
    cartSummaryPage: {
      title: 'Zhrnutie pred objednávkou',
      actions: {
        continueShopping: 'Pokračovať v nákupe',
        saveOrder: 'Uložiť objednávku',
      },
      expectedDeliveryDate: {
        label: 'Požadovaný dátum dodávky',
        helperText: '',
        helperTextPreview: '',
      },
      noteForSeller: {
        label: 'Poznámka pre dopravcov',
        helperText: '',
        helperTextPreview: '',
      },
      paymentGateway: {
        label: 'Platobná brána',
        helperText: '',
        helperTextPreview: '',
      },
    },
    userProfile: {
      title: 'Môj účet',
      content: 'Obsah',
      contactInfo: {
        newsletter: {
          title: 'Newsletter',
          content: 'Chcem dostávať novinky',
        },
        title: 'kontaktné údaje',
      },
      orders: {
        title: 'História objednávok',
      },
      invoices: {
        title: 'Faktúry',
      },
      myOrders: 'Objednávky',
      myInvoices: 'Faktúry',
      logout: 'Odhlásiť',
      login: 'Prihlásenie',
      favorites: {
        title: 'Obľúbené',
      },
      changePassword: {
        title: 'Zmena hesla',
        fields: {
          login: 'Login (nie je možné zmeniť)',
          originalPassword: 'Pôvodné heslo',
          newPassword: 'Nové heslo',
          confirmNewPassword: 'Potvrďte heslo',
        },
        button: 'Uložiť zmeny',
      },
    },
    orderSavedThankPage: {
      title: 'Ďakujeme za vašu objednávku {orderNumber}',
      thankMessage: {
        email: 'info@ceskyvelkoobchod.cz',
        phoneNumber: '',
        template:
          'Skontrolujte prosím svoju e-mailovú schránku pre potvrdenie objednávky. E-mail si uložte pre vlastnú potrebu. Všetky informácie o vašej objednávke nájdete v sekcii nižšie.',
      },
      order: 'Číslo objednávky:',
      payment: 'Platba dňa:',
      cancelled: 'Zrušené',
      accepted: 'Prijaté',
      pending: 'Čakajúce',
      paid: 'Zaplatené',
      actions: {
        back: 'Späť na hlavnú stránku',
        detail: 'Prehľad objednávky',
        continue: 'Pokračovať v nákupe',
      },
      decline: 'Odmietnuté',
    },
    shoppingLists: {
      title: 'Nákupný zoznam',
      actions: {
        saveAsShoppingList: 'Uložiť nákupný zoznam',
        importShoppingListToCart: 'Import',
      },
    },
    searchResultPage: {
      search: 'Hľadaný výraz',
      results: 'Výsledky vyhľadávania',
    },
    homePageArticles: {
      title: 'Aktuality',
      button: 'Zobraziť všetky články',
    },
    loginPage: {
      title: 'Prihlásenie',
      forgotPassword: 'Resetovať heslo',
      email: 'E-mailom',
      sendEmail: 'Poslať email',
      info: 'Dostanete e-mail s odkazom na zmenu hesla',
      password: 'Heslo',
      notSame: 'Heslo sa nezhoduje',
      newPassword: 'Nové heslo',
      confirmPassword: 'Potvrďte heslo',
      changePassword: 'Zmena hesla',
    },
    productPrice: {
      withoutTax: 'bez DPH',
    },
    productDetailPage: {
      availability: {
        dateRange: 'Objednajte {today} a my vám odošleme počas {minDays}–{maxDays} dní.',
        today: 'Objednajte dnes do {time} a ešte dnes odošleme.',
        todayWord: 'dnes',
        tomorrow: 'Objednajte {today} a odošleme zajtra.',
        nextDateTime: '13:30',
        dayOfWeek: {
          message: 'Objednajte si {today} a my vám ho pošleme {day}.',
          1: 'v pondelok',
          2: 'v utorok',
          3: 'v stredu',
          4: 'vo štvrtok',
          5: 'v piatok',
          6: 'v sobotu',
          0: 'v nedeľu',
        },
      },
      allDescription: 'ÚPLNÝ POPIS PRODUKTU',
      demoProduct: {
        text: 'KÚPIŤ LACNEJŠIE za',
      },
      productNote: {
        title: 'KÚPIŤ LACNEJŠIE za',
        note: 'Poznámka 2 – Dodatočný popis stavu alebo podobné informácie o produkte, prečo má zľavu a čo je na tovare...',
      },
      adornments: {
        notForSale: 'Nie je na predaj',
        usedProduct: 'Použité',
        freeGift: 'Darček zadarmo',
        freeShipping: 'Doprava zadarmo',
        eol: 'Predaj ukončený',
        demo: 'Demo produkt',
        sale: 'Zľava',
      },
      disponibility: {
        primaryLessThan: 'SKLADOM menej ako {count} kusov',
        primaryMoreThan: 'SKLADOM {count, plural, zero {jeden kus} other {{count} a viac kusov}}',
        secondaryLessThan: 'NA SKLADE v prípravnom sklade menej ako {count} kusov',
        secondaryMoreThan:
          'SKLADOM v prípravnom sklade {count, plural, zero {jeden kus} other {{count} a niekoľko kusov}}',
        external: 'Produkt je na ceste do nášho skladu',
        unavailable: 'Produkt nie je dostupný',
        showroom: 'Vystavené v showroome',
        soldout: 'Produkt je vypredaný',
        'on-way': {
          d: '',
          m: 'Na ceste za {from, plural, one {minúta} two {minúty} other {minúty}}',
          M: 'Na ceste {from, plural, one {mesiac} two {mesiace} few {mesiacov} other {mesiacov}}',
          dRange: 'Na ceste {to, plural, few {{from}-{to} dní} other {{from}-{to} dní}}',
          mRange: 'Na ceste {from}-{to, plural, one {deň} few {dni} other {dni}}',
          MRange: 'Na ceste {from}-{to, plural, one {mesiac} two {mesiace} few {mesiacov} other {mesiacov}}',
          date: '{datum}',
        },
        time: 'Na ceste pre {count, plural, zero {jeden kus} other {{count} a viac kusov}}',
      },
    },
    comparePage: {
      title: 'Porovnať',
      removeButton: 'Odstrániť z porovnania',
      nothing: 'Žiadne produkty na porovnanie',
    },
    filter: {
      reset: 'Resetujte filter',
      search: 'Vyhľadávanie',
    },
    orderTermsAndConditions: {
      select: {
        label: 'Podmienky objednávky',
        helperText: '',
        helperTextPreview: '',
      },
    },
    orderDetailPage: {
      actions: {
        import: 'pridať do košíka',
      },
    },
    invoiceBlogPreview: {
      title: 'Moje účty',
      button: 'Všetky účty',
    },
    userProfilePage: {
      logout: 'Odhlásiť',
      setting: 'Nastavenia',
      passwordChange: 'Zmena hesla',
    },
    appBarActionRegion: {
      changeRegion: 'Vyberte jazyk',
      label: 'OK',
    },
    subCategories: {
      title: 'Podkategórie',
    },
    aboutShoppingPage: {
      title: 'VŠETKO O NÁKUPE',
      contact: 'KONTAKT',
      electronicClosureOfRevenue: 'ELEKTRONICKÁ EVIDENCIA VÝNOSOV',
      informationDuty: 'INFORMAČNÁ POVINNOSŤ',
      processingOfPersonalData: 'SPRACOVANIE OSOBNÝCH ÚDAJOV',
      cashback: 'CASHBACK',
      delivery: 'DOPRAVA',
      availability: 'DOSTUPNOSŤ',
      methodOfPayment: 'SPÔSOB PLATBY',
      termsAndConditions: 'OBCHODNÉ PODMIENKY',
      termsOfAppeal: 'REKLAMAČNÝ PORIADOK',
      contractwWithdrawal: 'ODSTÚPENIE OD ZMLUVY',
    },
    customer: {
      viesValidation: {
        viesVerified: 'Vaše daňové číslo bolo overené, ceny sú účtované bez DPH.',
        viesNotVerified: 'Vaše daňové číslo nebolo overené alebo nespĺňate podmienky pre nákup bez DPH.',
      },
    },
    cartCustomerForm: {
      dataForDelivery: 'OSOBNÉ ÚDAJE',
      pleaseComplete: 'Vaše údaje',
      closeButton: 'Zavrieť',
    },
    cartPromoCodes: {
      title: 'UPLATNIŤ PROMO KÓD',
      textFieldPlaceholder: 'PROMO KÓD',
      textFieldHelperText: 'Je možné uplatniť iba jeden promo kód na objednávku',
      applyPromoCodeButton: 'UPLATNIŤ',
    },
    cookiesBar: {
      agreeBtn: 'OK',
      title: 'UPOZORNENIE O COOKIES NA NAŠOM WEBE',
      text: 'Naša spoločnosť používa na tomto webe svoje vlastné súbory cookie (tzv. základné súbory cookie) a súbory cookie tretích strán. Na základe týchto súborov a záujmu prispôsobujeme webové stránky tak, aby spĺňali očakávania zákazníkov, optimalizovali naše ponuky a predchádzali riziku podvodu. Bez niektorých potrebných súborov cookie nemôže web správne fungovať. Kliknutím na tlačidlo „Prijať všetko“ súhlasíte s ich používaním vrátane informácií o správaní webu. Svoj súhlas môžete kedykoľvek odvolať.',
    },
    cartFormLogin: {
      btnSend: 'Poslať',
      button: 'Prihlásiť sa',
      title: 'prihlásenie',
      forgottenPasswordBtn: 'Zabudli ste heslo?',
      text1: 'Na zadanú e-mailovú adresu vám zašleme jednorazové heslo.',
      text2: 'Po prihlásení si nastavte nové heslo.',
      error: 'Nesprávne užívateľské meno alebo heslo.',
    },
    cartSummartSection: {
      title: 'PREHĽAD OBJEDNÁVKY',
    },
    appCartSummarySubjectsList: {
      title: 'PRODUKT',
      text: 'OPIS',
    },
    newsletterText: {
      text1: 'Potrebujete poradiť?',
      text2: 'Volať nám môžete každý pracovný deň od 8 do 16 hodín.',
    },
    cartSummarySection: {
      labelWithoutVAT: '(bez DPH)',
      priceLabel: 'CENA',
      amount: 'MNOŽSTVO',
      totalPriceLabel: 'SPOLU',
      totalPrice: 'Celkom',
      submitButton: 'ODOSLAŤ OBJEDNÁVKU',
    },
    cartSummarySubjectsListItemDirectPreview: {
      amountTitle: '',
    },
    cartSummaryListItem: {
      freeTitle: '- Zadarmo',
      amountTitle: 'ks',
    },
    sxCartNotes: {
      note: 'Neslúži na označenie dátumu dodania',
      addNote: 'Pridať poznámku',
    },
    productDetail: {
      promoCode: {
        title: 's promo kódom:',
        validTo: 'platí do:',
      },
      productSendLink: {
        copyLinkBtn: 'Skopírujte odkaz',
        sendLinkByEmail: 'Pošlite odkaz emailom',
      },
      productDetailBarInformation: {
        addToFavourites: 'Pridať k obľúbeným',
        compareProduct: 'Porovnať produkt',
        sendLink: 'Poslať odkaz',
        serviceList: 'Zoznam služieb',
        questions: 'Otázky',
      },
    },
    cartFormShipping: {
      checkbox: {
        invoiceToCompany: 'KÚPIŤ NA FAKTÚRU',
        otherDeliveryInfo: 'DORUČIŤ NA INÚ ADRESU',
        otherDeliveryInfoAlert: 'Platiteľ DPH nemôže na doručovanie použiť inú adresu.',
      },
      companyNameLabel: 'Názov firmy',
      firstNameLabel: 'Meno',
      lastNameLabel: 'Priezvisko',
      streetLabel: 'Ulica',
      cityLabel: 'Mesto',
      zipLabel: 'PSČ',
      companyIDLabel: 'IČO',
      countryLabel: 'Štát',
    },
    cartFormPersonal: {
      label: 'Doručovacia adresa',
      firstNameLabel: 'Meno',
      lastNameLabel: 'Priezvisko',
      streetLabel: 'Ulica a číslo domu',
      pscLabel: 'PSČ',
      cityLabel: 'Mesto',
      countryLabel: 'Štát',
      phoneLabel: 'Telefónne číslo',
      emailLabel: 'Emailová adresa',
      passwordLabel: 'Heslo',
      controlPasswordLabel: 'Kontrolné heslo',
    },
    cartFormInvoice: {
      labelDesktop: 'ÚDAJE K PLATBE / (údaje budú vytlačené na faktúre)',
      labelMobile: 'kontaktné údaje',
      companyLabel: 'Firma',
      password: 'Heslo',
      confirmPassword: 'Overenie hesla',
      email: 'Emailová adresa',
      firstName: 'Meno',
      lastName: 'Priezvisko',
      countryLabel: 'Štát',
      phone: 'Telefónne číslo',
      phoneError: 'Telefónne číslo nie je správne',
      streetLabel: 'Ulica',
      streetNumberLabel: 'Číslo domu',
      zipLabel: 'PSČ',
      icoLabel: 'IČO',
      dicLabel: 'DIČ',
      country: 'Štát',
      cityLabel: 'Mesto',
      registration: 'Chcem si otvoriť účet',
      registrationNote: 'Otvorte si účet a môžete sledovať svoje objednávky, zásielky a platby.',
      button: 'Uložiť zmeny',
    },
    page404: {
      title: 'Stránka bohužiaľ neexistuje...',
      subTitle: 'Pokračujte na hlavnú stránku.',
      button: 'Späť na hlavnú stránku.',
    },
    productListOrderSelectorButton: {
      selectLabel: 'Zoradené podľa:',
      byName: 'Názov',
      byPrice: 'Cena',
      onlyStock: 'Iba skladom',
      resetFilter: 'Zrušiť filtre',
      displayCountOfProducts: 'Produkty:',
      search: 'Vyhľadávanie',
      clear: 'Vymazať',
      ok: 'Filtrovať',
    },
    priceRangeSliderField: {
      title: 'Cenové rozpätie',
    },
    productListMultipleFilterMobileDialog: {
      headerTitle: 'Filtrovať podľa',
    },
    productListMultipleFilterMobile: {
      title: 'FILTER',
    },
    cartCompany: {
      companyName: 'Spoločnosť',
      regNo: 'IČO',
      taxNo: 'DIČ',
      taxNoPlaceholder: 'SK0000000000',
      warningMessage: {
        warning: 'Dôležité..',
        message: 'V prípade doručenia na inú adresu ako je adresa spoločnosti bude objednávka spoplatnená DPH.',
      },
    },
    searchResultsPage: {
      match: 'Našli sme {resultCountFormatted} výsledkov pre {searchText}.',
      nomatch: 'Pre {searchText} neboli nájdené žiadne výsledky.',
      matchingCategories: 'Nájdené kategórie',
      button: 'Späť na hlavnú stránku.',
    },
    selectMultipleFilter: {
      totalChipsLabel: 'Počet vybraných produktov: {total}',
    },
    cartDelivery: {
      firstName: 'Meno',
      lastName: 'Priezvisko',
      companyName: 'Spoločnosť',
      streetLabel: 'Ulica',
      streetNumberLabel: 'Číslo domu',
      zipLabel: 'PSČ',
      country: 'Štát',
      cityLabel: 'Mesto',
    },
    cartNotes: {
      shippingLabel: 'Pridajte poznámku dopravcovi',
      shippingNote: 'Poznámka pre dopravcov',
      sellerLabel: 'Pridajte poznámku pre predajcov',
      sellerNote: 'Poznámka pre predajcov',
      loginNote: 'Ak u nás už máte účet, formulár sa vyplní automaticky po prihlásení.',
    },
    cartCountryShipping: {
      title: 'KRAJINA',
      label: 'Výber krajiny môže ovplyvniť platbu, dopravu aj termín dodania',
    },
    cartAddress: {
      bllingAddress: 'Fakturačná adresa',
      deliveryAddress: 'Doručovacia adresa',
      pickUpPointAddress: 'Adresa výdajného miesta',
    },
    cartAgreements: {
      customerVerified:
        'Nesúhlasím so zasielaním dotazníka, ktorý je súčasťou programu "Overené zákazníkmi", ktorý je vykonávaný za účelom merania spokojnosti zákazníkov a za účelom skvalitnenia našich služieb."',
    },
    loginDialog: {
      username: 'Užívateľské meno',
      password: 'Heslo',
    },
    sprinxFormsValidation: {
      confirmPassword: 'Potvrďte heslo',
      invalidPostalCode: 'Zadané PSČ nie je v platnom formáte',
      usernameIsNotFree: 'Užívateľ s týmto menom je už zaregistrovaný.',
      emailEmpty: 'Email je správny!',
    },
    invoices: {
      actions: {
        addNewInvoice: 'Pridať nový účet',
        all: 'Všetky účty',
      },
      listPage: {
        table: {
          columns: {
            downloadLink: 'PDF',
            number: 'Číslo',
            invoiceDate: 'Dátum faktúry',
            dueDate: 'Dňa',
            total: 'Bez DPH',
            totalWithTax: 'Celkom',
            billingAddress: 'Fakturačná adresa',
            orderNumber: 'Číslo objednávky',
            unpaidAmount: 'Nezaplatená čiastka',
          },
          filter: {
            number: 'Číslo',
          },
        },
      },
      scoringBlog: {
        invoiceRowsBlogPreview: {
          title: 'Riadky účtu',
          columns: {
            priceUnit: 'Cena',
          },
        },
      },
    },
    cartSummaryTotal: {
      totalPrice: 'Celková čiastka',
      totalPriceWithTax: 'Celková čiastka',
      tax: 'DPH',
      withoutTax: 'Celková cena bez DPH',
    },
    compare: {
      addToCompare: 'Produkt bol pridaný do porovnania',
      removeToCompare: 'Produkt bol odstránený z porovnania',
      price: 'Cena',
      ean: 'EAN',
    },
    favorite: {
      addToFavorite: 'Produkt bol pridaný do obľúbených',
      removeFromFavorite: 'Produkt bol odstránený z obľúbených',
    },
    registration: {
      important: 'DÔLEŽITÉ!',
      note: 'Jednorazové heslo vám zašleme e-mailom. Po prihlásení si zmeňte heslo.',
      gdprAgreement: 'Musíte súhlasiť so spracovaním osobných údajov',
      submitButton: 'Registrovať',
      fail: 'Registrácia nebola úspešná, skúste to znova neskôr',
      success: 'Registrácia bola úspešná',
      title: 'Registrácia',
      dialog: 'Pokiaľ u nás ešte nemáte účet, môžete sa zaregistrovať.',
      here: 'tu',
    },
    appBarActionGlobalSearchComposed: {
      label: 'Hľadať produkty',
    },
    appBarActions: {
      about: 'Všetko o nákupe',
      aboutHref: '/vsetko-o-nakupe',
      compare: 'Porovnanie',
    },
    appBarActionUser: {
      title: 'Prihlásenie',
    },
    footer: {
      copyright: 'COPYRIGHT © ČESKÝ VELKOOBCHOD S.R.O. - všetky práva vyhradené.',
    },
    appArticlesPage: {
      title: 'Články',
    },
    errorPage: {
      title: 'Niečo je zle.',
      button: 'Hlavná strana',
    },
    cartSummary: {
      withoutTax: 'bez DPH',
    },
    renewPasswordForm: {
      success: 'Na vašu e-mailovú adresu bolo zaslané nové heslo.',
      emailNotFound: 'E-mail nebol nájdený!',
      error: 'Niečo je zle',
    },
    cartSubjectListItemQuantityWarning: 'Maximálne množstvo objednávky je {count} produktov',
  },
};
export default messages;

const messages = {
  sk: {
    sprinxFormsValidation: {
      required: 'Políčko je vyžadované!',
      string: 'Musí to byť textový reťazec!',
      stringEmpty: 'Nemôže byť prázdne',
      stringMin: 'Dĺžka musí byť väčšia alebo rovná {expected} znakom!',
      stringMax: 'Dĺžka musí byť menšia alebo rovná {expected} znakom!',
      stringLength: 'Dĺžka musí mať {expected} znakov!',
      stringPattern: 'Nezodpovedá požadovanému vzoru!',
      stringContains: "Je potrebné zahrnúť text '{expected}'!",
      stringEnum: 'Nezodpovedá žiadnej z povolených hodnôt!',
      stringNumeric: 'Musí byť číslo!',
      number: 'Musí byť číslo!',
      numberMin: 'Musí byť väčší alebo rovný {expected}!',
      numberMax: 'Musí byť menší alebo rovný {expected}!',
      numberEqual: 'Musí byť rovnaké ako {expected}!',
      numberNotEqual: 'Nesmie byť rovnaké ako {expected}!',
      numberInteger: 'Musí byť celé číslo!',
      numberPositive: 'Číslo musí mať kladnú hodnotu!',
      numberNegative: 'Číslo musí mať zápornú hodnotu.',
      array: 'Musí byť zoznam!',
      arrayEmpty: 'Nesmie byť prázdny zoznam!',
      arrayMin: 'Musí byť aspoň {number, plural, one {1 produkt} few {{number} produktů} other {{number} produktov}}!',
      arrayMax:
        'Nemôže obsahovať viac ako {number, plural, one {1 produkt} few {{number} produktů} other {{number} produktov}}!',
      arrayLength:
        'Musí obsahovať presne {expected, plural, one {1 produkt} few {{number} produktů} other {{number} produktov}}!',
      arrayContains: "Musí obsahovať produkt '{expected}'!",
      arrayEnum: "Hodnota '{expected}' nezodpovedá žiadnej z povolených hodnôt!",
      boolean: 'Musí byť „áno“ alebo „nie“!',
      function: 'Musí byť funkcia!',
      date: 'Musí byť dátum!',
      dateMin: 'Musí byť väčší alebo rovný {expected}!',
      dateMax: 'Musí byť menší alebo rovný {expected}!',
      forbidden: 'Zakázané!',
      email: 'Email je správny!',
      url: 'Neplatná adresa URL!',
      object: 'Musí byť typu Objekt!',
      arrayReducedEquals: 'Súhrnná hodnota nezodpovedá {expected}!',
      phoneNumber: "Telefónne číslo musí byť v platnom medzinárodnom formáte! '+ XXX XXX XXX XXX '!",
      dateGt: 'Dátum musí byť väčší ako {expected}!',
      dateGte: 'Dátum musí byť väčší alebo rovný {expected}!',
      dateLt: 'Dátum musí byť menší ako {expected}!',
      dateLte: 'Dátum musí byť menší alebo rovný {expected}!',
    },
  },
};
export default messages;

const messages = {
  sk: {
    app: {
      about: 'O aplikácii',
      actions: {
        add: 'Pridať',
        addNamed: 'Pridať {name}',
        remove: 'Zmazať',
        removeNamed: 'Zmazať {name}',
        removeConfirmation: 'Ste si istí, že to chcete zmazať?',
        more: 'Viac',
        less: 'Menej',
        download: 'Download',
        save: 'Uložiť',
        cancel: 'Zrušiť',
        close: 'Zavrieť',
        moreFormFields: 'Ďalšie polia',
        export: 'Export',
        edit: 'Upraviť',
        createdSuccessMessage: 'Úspešne vytvorené.',
        savedSuccessMessage: 'Úspešne uložené.',
        deletedSuccessMessage: 'Úspešne zmazané.',
        detail: 'Detail',
        next: 'Ďalšia',
        back: 'Späť',
        start: 'Začiatok',
        whatNext: 'Kam ďalej',
        confirm: 'Potvrdiť',
        confirmRemove: 'Ste si istí, že to chcete zmazať?',
        search: 'Vyhľadávanie',
        userProfile: 'Užívateľský profil',
        tenantProfile: 'Váš CRM profil',
        imports: 'Import',
        fileImports: 'Importovať súbory',
        settings: 'Nastavenia',
        logout: 'Odhlásiť',
        login: 'Prihlásiť',
        filterList: 'Filtre',
        excel: 'Excel',
        refresh: 'Resetovať',
        clear: 'Vymazať',
        agree: 'súhlasím',
        disagree: 'Nesúhlasím',
        makeCopy: 'Kopírovať',
        fullscreen: 'Zobraziť na celej obrazovke',
        fullscreenClose: 'Ukončiť zobrazenie na celú obrazovku',
        copy: 'kopírovať',
      },
      messages: {
        confirmRemove: 'Naozaj chcete tento produkt zmazať?',
        errorOccurred: 'Vyskytla sa chyba',
        createdSuccess: 'Vytvorenie bolo úspešné.',
        savedSuccess: 'Uloženie bola úspešné.',
        deletedSuccess: 'Zmazanie bolo úspešné.',
        uploadSuccess: 'Súbor bol úspešne nahraný.',
        uploadError: 'Chyba pri nahrávaní súboru.',
        uploadInProgress: 'Načítanie...',
        uploadedFiles:
          '{number, plural, one {1 súbor nahraný} few {{number} nahrané súbory} other {{number} súborov bolo nahraných}}',
      },
      error: 'Error',
      validations: {
        isRequired: 'Pole {field} je povinné.',
        minLengthIsRequired:
          'Je vyžadované aspoň {number, plural, one {1 položka} few {{number} položek} other {{number} produktov}}.',
        minValue: 'Minimálna povolená hodnota je {value}.',
        maxValue: 'Maximálna povolená hodnota je {value}.',
        passwordMatchError: 'Heslá sa nezhodujú',
        invalidEmail: 'E-mail je neplatný.',
        invalidPhoneNumber: "Telefónne číslo musí byť v platnom medzinárodnom formáte! '+ XXX XXX XXX XXX '!",
        notValidNumber: 'Neplatné číslo',
        invalidUrl: 'Neplatná adresa URL.',
        positive: 'Číslo musí mať kladnú hodnotu.',
        negative: 'Číslo musí mať zápornú hodnotu.',
      },
      login: {
        title: 'Prihláste sa do Sprinx CRM',
        username: 'Užívateľské meno',
        password: 'Heslo',
        loginButton: 'Prihlásenie',
        error: 'Prihlásenie nebolo úspešné. Užívateľské meno alebo heslo nebolo nájdené',
        pleaseRelogin: 'Zdá sa, že nie ste prihlásený. Skúste sa prosím prihlásiť znova.',
      },
      days: '{count, plural, one {1 deň} few {{formattedCount} dní} other {{formattedCount} dni}}',
      overdue: 'oneskorenie {count, plural, one {1 deň} few {{formattedCount} dní} other {{formattedCount} dni}}',
      newItem: 'Nový',
      anonymizing: {
        confirm: {
          title: 'Potvrďte anonymizáciu',
          content: 'Naozaj chcete anonymizovať osobné údaje?',
        },
        button: 'Anonymizácia osobných údajov',
      },
      appMenu: {
        discover: 'Objaviť',
      },
      missingType: 'Najprv prosím zadajte {typeName} v administrácii aplikácie.',
      missingTypeForEntityType: 'Najprv prosím definujte {typeName} pre {forEntityTypeName} v administrácii aplikácie.',
      emptyScoringBlog: 'Moduly, ktorých obsah alebo nastavenia sú zobrazené na tejto stránke, nie sú aktivované.',
    },
    components: {
      buttons: {
        showOnMap: 'Zobraziť na mape',
      },
      filters: {
        filterButtonSubmit: 'Vyhľadávanie',
        filterFieldName: '{name} ({conditionType})',
        addCondition: 'Pridať podmienku',
        resetFilter: 'Zrušiť filter',
        operators: {
          contains: ['obsahuje'],
          containsexact: ['presne obsahuje'],
          notcontains: ['neobsahuje'],
          betweennumber: ['od', 'do'],
          betweendate: ['od', 'do'],
          equal: ['je rovnaký'],
        },
      },
      paginationList: {
        itemsTotal: 'Celkový počet produktov: {number}',
        nextPage: 'Zoznam produktov: {number}',
        emptyMessage: 'Neboli nájdené žiadne produkty.',
      },
      selects: {
        emptyOption: 'Prázdne',
        emptyMessage: 'Neboli nájdené žiadne produkty.',
      },
      tables: {
        labelRowsPerPage: 'Riadok na stránke',
        sortBy: 'Zoradiť podľa {field}',
        recordsChanged: 'Niektoré záznamy boli pridané alebo zmenené.',
      },
      textField: {
        errorMsgWrapper: '',
      },
      dateField: {
        invalidDateFormat: 'Nesprávny formát dátumu.',
        invalidDate: 'Chybný dátum.',
        maxDateMessage: 'Dátum nemôže byť väčší ako maximálny dátum',
        minDateMessage: 'Dátum nesmie byť kratší ako minimálny dátum',
        today: 'Dnes',
      },
      detailNavigation: {
        scoringblog: 'Scoring blog',
        card: 'Karta',
        relations: 'Byť',
      },
      manipulationInfo: {
        created: 'Vytvorené',
        updated: 'Zmenené',
        removed: 'Zmazané',
      },
    },
  },
};
export default messages;

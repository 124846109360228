import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { pickHTMLProps } from 'pick-react-known-prop';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    tagContainer: {
      padding: theme.spacing(0, 1),
      '& img': {
        width: 90,
        height: 90,
        margin: theme.spacing(0, 1.5),
        [theme.breakpoints.down('md')]: { width: 70, height: 70, margin: theme.spacing(0, 1.25) },
      },
    },
  }),
  { name: 'ProductDetailCertificate' },
);

function ProductDetailCertificate({ classes: pClasses, className, ...rest }) {
  const classes = useStyles({ classes: pClasses });

  // Other Trusted program that is not used on SK localization
  /* React.useEffect(() => {
    const smdObject = {
      Key: 'Slo_2458',
      Type: 'badge',
      Version: 2,
      BadgeClassName: 'smdWrapperTag',
    };
    const smdWrapper = document.createElement('script');
    smdWrapper.id = '_smdWrapperTag';
    smdWrapper.type = 'text/javascript';
    smdWrapper.src = `https://cpx.smind.si/Log/LogData?data=${JSON.stringify(smdObject)}`;
    smdWrapper.async = true;
    document.body.insertBefore(smdWrapper, document.body.firstChild);

    return () => {
      const el = document.getElementById('_smdWrapperTag');
      document.body.removeChild(el);
    };
  }, []); */

  return (
    <div {...pickHTMLProps(rest)} className={clsx(classes.root, className)}>
      {/* <div className={clsx('smdWrapperTag', classes.tagContainer)} /> */}
      <div
        className={classes.tagContainer}
        style={{ background: 'transparent', textAlign: 'center', padding: 0, margin: '0 auto' }}
      >
        <a
          title="Heureka.sk - overené hodnotenie obchodu www.Digital24.sk"
          href="https://obchody.heureka.sk/digital24-sk/recenze/"
          target="_blank" rel="noreferrer"
        >
          <img
            src="https://im9.cz/sk/cb/13042-3.png"
            alt="Heureka.sk - overené hodnotenie obchodu www.Digital24.sk"
            style={{ borderStyle: 'none' }}
          />
        </a>
      </div>
    </div>
  );
}

ProductDetailCertificate.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    tagContainer: PropTypes.string,
  }),
  className: PropTypes.string,
};

ProductDetailCertificate.defaultProps = {
  classes: undefined,
  className: undefined,
};

export default ProductDetailCertificate;

export default {
  pricingRules: [
    {
      superGroup: 0,
      ruleThread: 'sale',
      ruleCode: 'sale',
      ruleName: 'Sale',
      ruleType: 'subject',
      targets: [{ triggers: [['taxonomy', ['/catalogue']]] }],
      map: ['subjectSale', ['SKU-SALE']],
      valueAdded: 'subjectPriceDifference',
    },
    {
      superGroup: -1,
      ruleThread: 'multi-gift-hr',
      ruleCode: 'multi-gift-hr',
      ruleName: 'Darek',
      ruleType: 'subject',
      noFootprint: true,
      targets: [{ triggers: [['taxonomy', ['/catalogue']]] }],
      map: ['subjectSingleProductMultipleGifts', ['product.pricingExtensions.gifts.SK']],
      valueAdded: 'subjectPriceDifference',
    },
    {
      superGroup: 1,
      ruleThread: 'free-shipping-product',
      ruleCode: 'free-shipping-product',
      ruleName: 'Doprava zdarma',
      ruleType: 'cart',
      targets: [{ constraints: [['cartTaxonomyQuantitySum', ['/free-shipping', 'gte', 1]]] }],
      map: ['cartShippingFixPrice', [0, 'SHIPPING-FREE']],
    },

    // {
    //   superGroup: -2.0,
    //   ruleThread: 'promo-code',
    //   ruleCode: 'promo-code-kupon-1',
    //   ruleName: 'promo-code-kupon-1',
    //   ruleType: 'subject',
    //   map: [
    //     'subjectIndirectPercentDiscountFromTotal',
    //     [
    //       [
    //         {
    //           triggers: [['taxonomy', ['/kupon-1']]],
    //           constraints: [['subjectQuantity', ['gte', 1]]],
    //           promoCode: 'SLEVA5',
    //         },
    //       ],
    //       ['subjectsNumber', ['gte', 1]],
    //       0.05,
    //       {
    //         sku: '7158_kupon',
    //         name: 'SLEVOVÝ KUPON -5%',
    //         image: [
    //           [
    //             'miniSF',
    //             'https://media-server.stages.udolni.net/cvoEshop/image/5e8468b68a511f00230bcaec_irobot-roomba-i7-1-1_miniSF.png',
    //           ],
    //         ],
    //       },
    //     ],
    //   ],
    //   valueAdded: 'subjectPriceIndirect',
    //   targets: [{ triggers: [['taxonomy', ['/kupon-1']]] }],
    // },
    // {
    //   superGroup: -2.0,
    //   ruleThread: 'promo-code',
    //   ruleCode: 'promo-code-kupon-2',
    //   ruleName: 'promo-code-kupon-2',
    //   ruleType: 'subject',
    //   map: [
    //     'subjectIndirectPercentDiscountFromTotal',
    //     [
    //       [
    //         {
    //           triggers: [['taxonomy', ['/kupon-2']]],
    //           constraints: [['subjectQuantity', ['gte', 1]]],
    //           promoCode: 'SLEVA10',
    //         },
    //       ],
    //       ['subjectsNumber', ['gte', 1]],
    //       0.1,
    //       {
    //         sku: 'BRI920/00_kupon',
    //         name: 'SLEVOVÝ KUPON -10%',
    //         image: [
    //           [
    //             'miniSF',
    //             'https://media-server.stages.udolni.net/cvoEshop/image/5f0fdf457edc35001c6f8aeb_philips-bri920-00-lumea-advanced-ipl-1_miniSF.png',
    //           ],
    //         ],
    //       },
    //     ],
    //   ],
    //   valueAdded: 'subjectPriceIndirect',
    //   targets: [{ triggers: [['taxonomy', ['/kupon-2']]] }],
    // },
  ],
  pricingRulesCompiled: [
    // [
    //   -2,
    //   [
    //     {
    //       superGroup: -2,
    //       ruleThread: 'promo-code',
    //       ruleCode: 'promo-code-kupon-1',
    //       ruleName: 'promo-code-kupon-1',
    //       ruleType: 'subject',
    //       map: [
    //         'subjectIndirectPercentDiscountFromTotal',
    //         [
    //           [
    //             {
    //               triggers: [['taxonomy', ['/kupon-1']]],
    //               constraints: [['subjectQuantity', ['gte', 1]]],
    //               promoCode: 'SLEVA5',
    //             },
    //           ],
    //           ['subjectsNumber', ['gte', 1]],
    //           0.05,
    //           {
    //             sku: '7158_kupon',
    //             name: 'SLEVOVÝ KUPON -5%',
    //             image: [
    //               [
    //                 'miniSF',
    //                 'https://media-server.stages.udolni.net/cvoEshop/image/5e8468b68a511f00230bcaec_irobot-roomba-i7-1-1_miniSF.png',
    //               ],
    //             ],
    //           },
    //         ],
    //       ],
    //       valueAdded: 'subjectPriceIndirect',
    //       targets: [{ triggers: [['taxonomy', ['/kupon-1']]] }],
    //     },
    //     {
    //       superGroup: -2,
    //       ruleThread: 'promo-code',
    //       ruleCode: 'promo-code-kupon-2',
    //       ruleName: 'promo-code-kupon-2',
    //       ruleType: 'subject',
    //       map: [
    //         'subjectIndirectPercentDiscountFromTotal',
    //         [
    //           [
    //             {
    //               triggers: [['taxonomy', ['/kupon-2']]],
    //               constraints: [['subjectQuantity', ['gte', 1]]],
    //               promoCode: 'SLEVA10',
    //             },
    //           ],
    //           ['subjectsNumber', ['gte', 1]],
    //           0.1,
    //           {
    //             sku: 'BRI920/00_kupon',
    //             name: 'SLEVOVÝ KUPON -10%',
    //             image: [
    //               [
    //                 'miniSF',
    //                 'https://media-server.stages.udolni.net/cvoEshop/image/5f0fdf457edc35001c6f8aeb_philips-bri920-00-lumea-advanced-ipl-1_miniSF.png',
    //               ],
    //             ],
    //           },
    //         ],
    //       ],
    //       valueAdded: 'subjectPriceIndirect',
    //       targets: [{ triggers: [['taxonomy', ['/kupon-2']]] }],
    //     },
    //   ],
    // ],
    [
      -1,
      [
        {
          superGroup: -1,
          ruleThread: 'multi-gift-hr',
          ruleCode: 'multi-gift-hr',
          ruleName: 'Darek',
          ruleType: 'subject',
          noFootprint: true,
          map: ['subjectSingleProductMultipleGifts', ['product.pricingExtensions.gifts.SK']],
          valueAdded: 'subjectPriceDifference',
          targets: [{ triggers: [['taxonomy', ['/catalogue']]] }],
        },
      ],
    ],
    [
      0,
      [
        {
          superGroup: 0,
          ruleThread: 'sale',
          ruleCode: 'sale',
          ruleName: 'Sale',
          ruleType: 'subject',
          map: ['subjectSale', ['SKU-SALE']],
          valueAdded: 'subjectPriceDifference',
          targets: [{ triggers: [['taxonomy', ['/catalogue']]] }],
        },
      ],
    ],
    [
      1,
      [
        {
          superGroup: 1,
          ruleThread: 'free-shipping-product',
          ruleCode: 'free-shipping-product',
          ruleName: 'Doprava zdarma',
          ruleType: 'cart',
          map: ['cartShippingFixPrice', [0, 'SHIPPING-FREE']],
          targets: [{ constraints: [['cartTaxonomyQuantitySum', ['/free-shipping', 'gte', 1]]] }],
        },
      ],
    ],
  ],
};

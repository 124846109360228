const messages = {
  sk: {
    shoppingCart: {
      actions: {
        addToCart: 'Pridať do košíka',
        buy: 'Kúpiť',
        order: 'Objednať',
        remove: 'Odstrániť',
      },
      cart: {
        total: {
          label: 'Celkom',
          helperText: '',
          helperTextPreview: '',
        },
        totalItems: {
          label: 'Celkom',
          helperText: '',
          helperTextPreview: '',
        },
        totalShipping: {
          label: 'Celkom',
          helperText: '',
          helperTextPreview: '',
        },
        totalPayment: {
          label: 'Celkom na zaplatenie',
          helperText: '',
          helperTextPreview: '',
        },
        totalTaxes: {
          label: 'DPH',
          helperText: '',
          helperTextPreview: '',
        },
        resetCart: 'Resetujte košík',
      },
      messages: {
        confirmResetCart: 'Naozaj chcete zmazať obsah košíka?',
        giftClaim: 'Môžete získať {quantity} kusov {productName} ako darček.',
        cartManipulations: {
          allRemoved: 'Všetky položky boli odstránené.',
          itemAdded: '{number, plural, one {položka pridaná do košíka} other {položky pridané do košíka}}.',
          itemRemoved: '{number, plural, one {Položka odstránená z košíka} other {Položky odstránené z košíka}}.',
          quantityChaged: 'Množstvo sa zmenilo.',
        },
      },
      components: {
        appBartActionCart: 'Košík',
        quantityField: {
          states: {
            quantityRounded: 'Množstvo bolo zaokrúhlené na najbližšiu veľkosť balenia.',
          },
        },
        cartAsidePreview: {
          cartEmpty: 'Váš nákupný košík je prázdny',
        },
        cartAfterAddModal: {
          continueShopping: 'Pokračovať v nákupe',
          goToCart: 'Prejsť do košíka',
          title: 'Produkty boli pridané do košíka',
          pcs: 'ks',
        },
      },
    },
  },
};
export default messages;

import PropTypes from 'prop-types';
import comOrdersMessages from './react-com-orders/sk';
import comMessages from './react-com/sk';
import formsMessages from './react-forms/sk';
import compMessages from './react-mui-components/sk';
import tablesMessages from './react-mui-tables/sk';
import shoppingCartMessages from './react-shopping-cart/sk';
import messages from './sk';

// const rename = ({ sr }) => ({ hr: sr });

function MessagesSk({ children }) {
  return children(
    [
      compMessages,
      // fieldMessages,
      tablesMessages,
      formsMessages,
      // pagesMessages,
      comMessages,
      // shoppingListMessages,
      shoppingCartMessages,
      comOrdersMessages,
      // invoicesMessages,
      messages,
    ], // .map(rename),
  );
}

MessagesSk.propTypes = {
  children: PropTypes.func.isRequired,
};

export default MessagesSk;
